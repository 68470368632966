import React, { Component, Suspense } from 'react';
import TrackO from "../images/lokal/track-0.png"
import DelTime from "../images/lokal/del-time.png"
import Office from "../images/lokal/office.png"
import DelPartner from "../images/lokal/delivery-partner.png"
import Amount from "../images/lokal/amount.png"
import { FiEdit } from "react-icons/fi";
import { TiHome } from "react-icons/ti";
import { FaCheck } from "react-icons/fa6";
import Offer from "../images/lokal/offer.png"
import TrackLeft from "../images/lokal/track-order.png"
import TrackLine from "../images/lokal/track-order-line.png"

const Header = React.lazy(() => import("../luna/header"));
const Footer = React.lazy(() => import("../luna/footer"));

export default class Storelisting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 2,  // Make steps 1 and 2 expanded by default
    };
  }

  renderContent = () => {
    const { activeOption } = this.state;
    // const { showPopup } = this.state;

  };
  render() {
    const { activeStep, showPopup } = this.state;

    return (
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Header />
          <div className='main-cart track-order-main'>
            <div className='container-fluid outer-padding'>
              <div className="track-order-outer">
                <div className='pt-3 pb-5'>
                  <span className='mr-3'>
                    <img src={TrackLeft} alt="TrackLeft" width="20" height="20" />
                  </span>
                  <span className='mr-3'>
                    <img src={TrackLine} alt="TrackLine" width="auto" height="20" />
                  </span>
                  <span className='track-order-head'>Track Order</span>
                </div>
                <div className='d-md-flex align-items-center bottom-border'>
                  <div>
                    <span></span>
                    <div className='d-flex align-items-center'>
                      <div>
                        <img src={TrackO} alt="TrackO" className='img-fluid' width="50"
                          height="50" />
                      </div>
                      <div className='d-flex  flex-column ml-3'>
                        <div> Order no:</div>
                        <div className='order-number'>LKL100030045</div>
                      </div>
                    </div>
                  </div>
                  <div className='ml-5'>
                    <span className='delivery-time'>
                      <span>
                        <img src={DelTime} alt="TrackO" className='img-fluid mr-1' width="20"
                          height="20" />
                      </span>
                      Delivering in 15 minutes
                    </span>
                  </div>
                </div>
                <div className='d-flex row m-0 py-4'>
                  <div className='col-12 col-lg-4 col-md-4 pr-lg-0'>
                    <div className='bg-details'>
                      <div className='mb-2'>
                        <span> <img src={Office} alt="Office" className='img-fluid' width="30"
                          height="30" /></span>
                        <span className='ml-2 track-head'>Office</span>
                      </div>
                      <div className='details-long'>
                        A-507, Hiranandani Lighthall, Saki Vihar Road, Saki Naka, Chandavali,
                        Mumbai 400061
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-lg-4 col-md-4'>
                    <div className='bg-details'>
                      <div className='mb-2'>
                        <span>
                          <img src={DelPartner} alt="Office" className='img-fluid' width="30"
                            height="30" />
                        </span>
                        <span className='ml-2 track-head'>Delivery Partner</span>
                      </div>
                      <div className='details-long'>
                        Mr. Jaikanth Shinde
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-lg-4 col-md-4 pr-lg-0'>
                    <div className='bg-details'>
                      <div className='d-lg-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center mb-2'>
                          <span>
                            <img src={Amount} alt="Office" className='img-fluid' width="30"
                              height="30" />
                          </span>
                          <span className='ml-2 track-head'>Total Amount</span>
                        </div>
                        <div>
                          ₹ 412.43
                        </div>
                      </div>
                      <div className='details-long'>
                        <span>
                          <img src={Offer} alt="Offer" width="16" height="16"
                            className='img-fluid mr-1' />
                        </span>
                        You’ve saved 47.12 on this order
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-12 col-md-12 py-0 step-border">
                  <div className="stepper">
                    {/* Step 1 */}
                    <div className={`step ${activeStep >= 1 ? 'active' : ''}`}>
                      <div className="circle">
                        <FaCheck color="#fff" />
                      </div>
                      <div className="dotted-line"></div>
                      <div className="dotted-line"></div>
                      <div className="step-content">
                        <div className="step-header">
                          <span className="step-header-name">Order placed</span>
                        </div>
                        {activeStep >= 1 && (
                          <div className="address-content">
                            <p>
                              Amul Masti Spiced Buttermilk x 1, Indian Tomato x 2, Modern 100%
                              Whole Wheat - Bread x1, Epigamia Greek Yogurt - Mango x1
                            </p>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Step 2 */}
                    <div className={`step ${activeStep >= 2 ? 'active' : ''}`}>
                      <div className="circle">
                        <FaCheck color="#fff" />
                      </div>
                      <div className="step-content">
                        <div className="step-header">
                          <span className="step-header-name">Order picked</span>
                        </div>
                        {activeStep >= 2 && (
                          <div className="address-content">
                            <p>
                              Our delivery partner, Mr Jaikanth Shinde has picked your order.
                            </p>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Step 3 */}
                    <div className={`step ${activeStep >= 3 ? 'active' : ''}`}>
                      <div className="circle">
                        <FaCheck color="#fff" />
                      </div>
                      <div className="step-content">
                        <div className="step-header">
                          <span className="step-header-name">Order is on its way</span>
                        </div>
                        <div className="address-content">
                          <p>Our delivery partner, Mr Jaikanth Shinde has picked your 
                            order and is on its way.</p>
                        </div>
                      </div>
                    </div>

                    {/* Step 4 */}
                    <div className={`step ${activeStep >= 4 ? 'active' : ''}`}>
                      <div className="circle">
                        <FaCheck color="#fff" />
                      </div>
                      <div className="step-content">
                        <div className="step-header" >
                          <span className="step-header-name">Delivery partner has reached</span>
                        </div>
                        <div className="address-content">
                          <p>Our delivery partner, Mr Jaikanth Shinde has reached your location.</p>
                        </div>
                      </div>
                    </div>

                    {/* Step 5 */}
                    <div className={`step ${activeStep >= 5 ? 'active' : ''}`}>
                      <div className="circle">
                        <FaCheck color="#fff" />
                      </div>
                      <div className="step-content">
                        <div className="step-header" >
                          <span className="step-header-name">Order has been delivered</span>
                        </div>
                        <div className="address-content">
                          <p>Our delivery partner, Mr Jaikanth Shinde has delivered your order.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </Suspense>
      </div>
    );
  }
}
