import React, { Component, Suspense } from 'react'
import Arrow from "../images/lokal/ph_arrow-up.png"
import Grocery from "../images/lokal/Groceries-near.png"
import Store from "../images/lokal/store1.png"
import Timer from "../images/lokal/timer.png"
import $ from "jquery";
import { Link } from 'react-router-dom';
import { setData } from '../setDefaultData'
import { getData, pageHitAPICall } from '../services';
export default class Storelisting extends Component {
    constructor(props) {
        super(props)
        this.state = {
            store: [],
            storeGlobal: [],
            isData: '0',
            isLoading: '0'
        }
    }
    async componentDidMount() {
        let locationId = localStorage.getItem('locationId');
        let categoryId = window.sessionStorage.getItem('mainCategoryIdLokal');
        if (((localStorage.getItem('siteGlobalDelivery') === "false" && localStorage.getItem('isPincodeBased') === "false") && ((locationId === null || locationId === "null" || locationId === "") || (categoryId === null || categoryId === "null" || categoryId === "")))) {
            await setData.call()
            var slug = this.props.match.params.slug
            var slugSplit = slug.split("-")
            const postData = {
                functionName: "common/getLocationAndCategoryData",
                categoryName: slugSplit[0],
                loactionName: slug.split('-delivery-in-')[1].replace(/-/g, " ")
            }
            getData(postData).then((data) => {
                console.log('data.....common/getLocationAndCategoryData......', data)
                if (data.data.success === "1") {
                    localStorage.setItem('locationId', data.data.result.location[0].locationId);
                    localStorage.setItem('locationText', data.data.result.location[0].locationName)
                    localStorage.setItem('locationNameOnly', data.data.result.location[0].locationName)
                    window.sessionStorage.setItem('mainGroupCategory', data.data.result.category[0].mainGroupCategoryId)
                    window.sessionStorage.setItem('mainCategoryId', data.data.result.category[0].mainCategoryId);
                    window.sessionStorage.setItem('mainCategoryIdLokal', data.data.result.category[0].mainCategoryId)
                    window.sessionStorage.setItem('mainCategoryAlternativeName', data.data.result.category[0].alternativeName)
                    localStorage.setItem('cityId', data.data.result.location[0].cityId)
                    localStorage.setItem('cityText', data.data.result.location[0].cityName)
                    localStorage.setItem('cityNameOnly', data.data.result.location[0].cityName)
                    let title = data.data.result.category[0].seoTitle.replace('{{SiteName}}', window.sessionStorage.getItem('siteName')).replace('{{Location}}', localStorage.getItem('locationNameOnly'))
                    let description = data.data.result.category[0].seoDescription.replace('{{SiteName}}', window.sessionStorage.getItem('siteName')).replace('{{Location}}', localStorage.getItem('locationNameOnly'))
                    let slug = data.data.result.category[0].seoSlug.replace('{{City}}', localStorage.getItem('cityNameOnly').toLowerCase()).replace('{{Location}}', data.data.result.location[0].locationName.toLowerCase().trim().replace(/ /g, '-'))
                    let h1 = data.data.result.category[0].seoH1.replace('{{Location}}', localStorage.getItem('locationNameOnly'))
                    window.sessionStorage.setItem('mainCategorySeoTitle', title)
                    window.sessionStorage.setItem('mainCategorySeoDescription', description)
                    window.sessionStorage.setItem('mainCategorySeoSlug', slug)
                    window.sessionStorage.setItem('mainCategorySeoH1', h1)
                    window.location.reload()
                }
            })
        }
        else {
            window.scrollTo(0, 0)
            window.sessionStorage.setItem("offset1", 0)
            window.sessionStorage.setItem("offset2", 0)
            window.sessionStorage.setItem("ActiveageNumber", 0)
            window.sessionStorage.setItem("ActiveageNumber2", 0)
            var mainCategoryIdBeforLocation = window.sessionStorage.getItem('mainCategoryIdBeforLocation')
            if (mainCategoryIdBeforLocation !== null && mainCategoryIdBeforLocation !== '' && mainCategoryIdBeforLocation !== 'null') {
                categoryId = mainCategoryIdBeforLocation
                window.sessionStorage.setItem('mainCategoryIdLokal', categoryId)
                window.sessionStorage.setItem('mainCategoryId', categoryId)
            }
            const postData = {
                "functionName": "lokal/store",
                "myCategoryId": window.sessionStorage.getItem('siteCategoryId'),
                "locationId": locationId !== null ? locationId : "",
                "categoryId": categoryId
            }
            getData(postData).then((data) => {
                console.log("test", data.data.result)
                if (data.data.success === "1") {
                    if (data.data.result.storeData.length > 0 || data.data.result.storeGlobalResults.length > 0) {
                        window.sessionStorage.setItem('mainCategoryIdBeforLocation', '')
                        data.data.result.storeData = data.data.result.storeData.map((obj) => {

                            let url = window.sessionStorage.getItem('mainCategoryAlternativeName').toLowerCase() + '-shop-in-' + "anywhere" + '-' + obj.storeName.toLowerCase().trim().replace(/ /g, '-') + '-' + obj.storeId
                            if (localStorage.getItem('siteGlobalDelivery') === "false" && localStorage.getItem('isPincodeBased') === "false") {
                                url = window.sessionStorage.getItem('mainCategoryAlternativeName').toLowerCase() + '-shop-in-' + localStorage.getItem('locationNameOnly').toLowerCase().trim().replace(/ /g, '-') + '-' + obj.storeName.toLowerCase().trim().replace(/ /g, '-') + '-' + obj.storeId
                            }
                            obj.slug = url
                            return obj;
                        })
                        data.data.result.storeGlobalResults = data.data.result.storeGlobalResults.map((obj) => {
                            let url = window.sessionStorage.getItem('mainCategoryAlternativeName').toLowerCase() + '-shop-in-' + "anywhere" + '-' + obj.storeName.toLowerCase().trim().replace(/ /g, '-') + '-' + obj.storeId
                            if (localStorage.getItem('siteGlobalDelivery') === "false" && localStorage.getItem('isPincodeBased') === "false") {
                                url = window.sessionStorage.getItem('mainCategoryAlternativeName').toLowerCase() + '-shop-in-' + localStorage.getItem('locationNameOnly').toLowerCase().trim().replace(/ /g, '-') + '-' + obj.storeName.toLowerCase().trim().replace(/ /g, '-') + '-' + obj.storeId
                            }

                            obj.slug = url
                            return obj;
                        })

                        this.setState({
                            store: data.data.result.storeData,
                            storeGlobal: data.data.result.storeGlobalResults,
                            isData: '1',
                            isLoading: '1'
                        });
                    }
                    else {
                        this.setState({
                            store: data.data.result.storeData,
                            storeGlobal: data.data.result.storeGlobalResults,
                            isData: '0',
                            isLoading: '1'
                        });
                    }
                    $(".categoryPopUp").hide();
                }
                else {
                    this.setState({
                        isData: '0',
                        isLoading: '1'
                    });
                }
            });
            pageHitAPICall("", 10, window.sessionStorage.getItem('siteCategoryId'));
        }
    }
    setStore(storeObj) {
        window.sessionStorage.setItem('storeName', storeObj.storeName)
        window.sessionStorage.setItem('storeId', storeObj.storeId)
        window.sessionStorage.setItem('mainGroupCategory', storeObj.mainGroupCategory)
        localStorage.setItem('cityId', storeObj.cityId);
        window.sessionStorage.setItem('storeMobile', storeObj.mobileNumber);
        window.sessionStorage.setItem('storeEmail', storeObj.emailId);
    }
    addDefaultSrc(ev) {
        ev.target.src = $("#hid" + ev.target.id).val()
    }
    render() {
        return (
            <div>
                {console.log("cccccccccccccccc", this.props.storeList)}
                <div className='top-header'>
                    <div className='container-fluid outer-padding'>
                        <div className="row m-0">
                            <div className='bread-crumb col-12'>
                                <span className='link'>Home</span>
                                <span className='px-1'>
                                    <img src={Arrow} alt="Arrow" className='img-responsive' width="15" height="15" />
                                </span>
                                <span className='link'>{localStorage.getItem('locationNameOnly')}</span>
                                <span className='px-1'>
                                    <img src={Arrow} alt="Arrow" className='img-responsive' width="15" height="15" />
                                </span>
                                <span className='link-active'>{sessionStorage.getItem('mainCategoryAlternativeName')}</span>
                            </div>
                            <div className='grocery-near col-12'>
                                <div className="">
                                    <div className='store-listimg-bg'>
                                        <img src={Grocery} alt="Grocery" className='img-fluid' width="70" height="70" />
                                    </div>
                                </div>
                                <div className="">
                                    <div className='grocery-head'>{sessionStorage.getItem('mainCategoryAlternativeName')} Stores Near You</div>
                                    <div className='grocery-no'>
                                        {this.props.totalStores} Store
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='stores-near'>
                    <div className='container-fluid outer-padding'>
                        <div className="row m-0">
                            {this.props.storeList.map((store, index) => (
                                <div className="col-12 col-lg-6 col-md-6 mb-4">
                                    <Link className="store-list-a" onClick={(e) => this.setStore(store)} to={(store.         mainGroupCategory === 8 ? "/cmItemListing/0/0/0" : "/" + store. slug)} >
                                        <div className='store-list'>
                                            <div className='store-img'>
                                                <img onError={this.addDefaultSrc} id={'ng' + index} src={store.imageName} alt="store" className='img-fluid'
                                                    width="150" height="150" />
                                                <input type="hidden" id={"hidng" + index} value={store.sellerLogoPath} />
                                            </div>
                                            <div className='store-deatils'>
                                                <div className='store-name' title={store.storeName}>
                                                    {store.storeName}
                                                </div>
                                                {localStorage.getItem('isPincodeBased') === "false" &&
                                                    <div className='store-address' title={store.locality + ', ' + store.cityName}>{store.locality}, {store.cityName}
                                                    </div>}
                                                <div className='store-position'>
                                                    <div className="store-distance">
                                                        2.8 km
                                                    </div>
                                                    <div className='store-time'>
                                                        <span className='mr-2'><img src={Timer} alt="Timer" className='img-fluid' width="20" height="20" /></span>
                                                        43 mins
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                        
                                    </Link> 
                                                                    
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}