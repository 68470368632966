import React, { Component } from 'react';
// import 'bootstrap/dist/css/bootstrap.css'
import { getData, pageHitAPICall } from '../services';
import Savemore from "../itemlisting/savemore"
import $ from "jquery";
import CartBtn from "../headerComponents/addcartBtn"
import offer from "../images/icons/star.png"
import Contact from "../itemlisting/contact"
import { Link, NavLink } from 'react-router-dom';
import ProductDefault from "../images/product-default.png"
import { elasticLink, elasticHostURL } from "../config";
import ReactPaginate from 'react-paginate';
const AWS = require('aws-sdk');
const elasticsearch = require("elasticsearch");
AWS.config.region = 'us-west-1';

export default class PopularItemsNew extends Component {

  constructor(props) {
    super(props)
    this.state = {
      itemResult: [],
      cartItems: [],
      storeResults: [],
      imagePath: '',
      currencySymbol: '',
      cityId: '',
      selectedProduct: {},
      isSaveMore: '0',
      activePage: 1,
      rowCount: 0,
      divPagination: '0',
      isLoading: '0',
      itemPriceForSession: '',
      itemPackIdForSession: '',
      itemIdForSession: '',
      itemImageForSession: '',
      sisIdForSession: '',
      storeButtonName: '',
      ItemName: '',
      storeItemStockId: '',
      s3AccessKey: '',
      s3SecretKey: '',
      itemOffset: '',
      selectBoxValueCheck: 0,
      data: [],
      offset: 0,
      selected: null
    };

    this.saveMore = this.saveMore.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.contactForm = this.contactForm.bind(this);
    this.itemListing = this.itemListing.bind(this);
    this.itemLoad = this.itemLoad.bind(this);
  }

  itemLoad() {
    var itemOffset = this.state.itemOffset
    this.itemListing(itemOffset, '48')
  }

  async itemListing(offset, max) {
    debugger
    let themeId = localStorage.getItem('themeId')
    const client = new elasticsearch.Client({
      //host: 'https://search-myyshopp-xkprhmqmqmmhkfvrg6hgi3mvme.us-west-1.es.amazonaws.com', 
      host: elasticHostURL(),
      amazonES: {
        region: 'us-west-1',
        credentials: new AWS.Credentials(this.state.s3AccessKey, this.state.s3SecretKey)
      }
    });

    let storeId = window.sessionStorage.getItem('storeId');
    let cityId = localStorage.getItem('cityId');    
    var filters = [];
    var fields = ['store_id'];
    var paramsVal = [storeId]
    if (cityId !== null && cityId !== 'null' && cityId !== '') {
      fields = ['store_id', 'city_id'];
      paramsVal = [storeId, cityId]
    }
    for (var k = 0; k < fields.length; k++) {
      if (paramsVal[k] !== '*' && paramsVal[k] !== '') {
        var termq = { term: {} };
        termq.term[fields[k]] = paramsVal[k];
        filters.push(termq);
      }
    }
    var sortByFilter = [{ item_ranking: "asc" }, { "seller_item_name.keyword": "asc" }]
    var popularItemData;
    var totalCount
    const body = await client.search({
      index: elasticLink() + '-popular-items',
      from: offset, size: max,
      body: {
        query:
        {
          bool: {
            filter:
            {
              bool:
              {
                must: filters
              }
            }
          }
        },
        sort: sortByFilter
      }
    })
    console.log('body.hits.hits0000.........',body.hits.hits);
    debugger
    popularItemData = body.hits.hits
    totalCount = body.hits.total.value
    var selectedProduct = JSON.parse(localStorage.getItem('selectedCartProduct'))
    var finalDataSet = [];
    var finalDataSetNew = [];
    if (popularItemData.length > 0) {
      var finalDataSet = [];
      for (var i = 0; i < popularItemData.length; i++) {
        var finalDataSetSub = [];
        finalDataSet[i] = {
          initialCount: 0,
          rowcount: popularItemData[i]._source.rowcount,
          group_count: popularItemData[i]._source.group_count,
          city_id: popularItemData[i]._source.city_id,
          store_id: popularItemData[i]._source.store_id,
          brand_name: popularItemData[i]._source.brand_name,
          category_level1_id: popularItemData[i]._source.category_level1_id,
          category_level2_id: popularItemData[i]._source.category_level2_id,
          category_level3_id: popularItemData[i]._source.category_level3_id,
          brand_id: popularItemData[i]._source.brand_id,
          total_rank: popularItemData[i]._source.item_ranking,
          is_loose_item: popularItemData[i]._source.is_loose_item,
          denomination: popularItemData[i]._source.denomination,
          variation: popularItemData[i]._source.variation,
          hasContactUsWithoutPrice: popularItemData[i]._source.hascontactuswithoutprice,
          hasBuyOption: popularItemData[i]._source.hasbuyoption,
          storeButtonName:
            popularItemData[i]._source.storebuttonname === null
              ? ""
              : popularItemData[i]._source.storebuttonname,
          store_item_stock_ids_with_seperator: popularItemData[i]._source.store_item_stock_ids,
          item_ids_with_seperator: popularItemData[i]._source.item_ids,
          item_prices_with_seperator: popularItemData[i]._source.item_prices,
          pack_ids_with_seperator: popularItemData[i]._source.pack_ids,
          mrps_with_seperator: popularItemData[i]._source.mrps,
          logoPath_with_seperator: popularItemData[i]._source.logo_paths,
          stock_quantities_with_seperator:
            popularItemData[i]._source.stock_quantities,
          is_swap_items_with_seperator: popularItemData[i]._source.is_swap_items,
          logo_path_thumbs_with_seperator:
            popularItemData[i]._source.logo_path_thumbs,
          unit_with_seperator: popularItemData[i]._source.unit,
          is_offers_with_seperator: popularItemData[i]._source.is_offers,
          offer_text_with_seperator: popularItemData[i]._source.offer_text
        };
        if (popularItemData[i]._source.group_count > 1) {
          finalDataSet[i].is_brand_item = true;
          var itemIdsOld = popularItemData[i]._source.item_ids
            .replace(/\|/g, ",")
            .split(",");
          var group_count;
          for (var j = 0; j < popularItemData[i]._source.group_count; j++) {
            var isItemAdded = false;
            var index = -1;
            for (var k = 0; k < finalDataSetSub.length; k++) {
              if (finalDataSetSub[k] !== null && finalDataSetSub[k] !== '' && typeof finalDataSetSub[k] !== 'undefined') {
                if (itemIdsOld[j] === finalDataSetSub[k].item_ids) {
                  isItemAdded = true;
                  index = k;
                  break;
                }
              }
            }
            if (isItemAdded === false) {
              group_count++;
              finalDataSetSub[j] = {
                unit: popularItemData[i]._source.unit
                  .replace(/\|/g, ",")
                  .split(",")[j],
                store_item_stock_ids: popularItemData[
                  i
                ]._source.store_item_stock_ids
                  .replace(/\|/g, ",")
                  .split(",")[j],
                unit_type: popularItemData[i]._source.unit_type
                  .replace(/\|/g, ",")
                  .split(",")[j],
                item_ids: popularItemData[i]._source.item_ids
                  .replace(/\|/g, ",")
                  .split(",")[j],
                item_names: popularItemData[i]._source.item_names
                  .replace(/\|/g, ",")
                  .split(",")[j],
                is_offers: popularItemData[i]._source.is_offers
                  .replace(/\|/g, ",")
                  .split(",")[j],
                offer_text: popularItemData[i]._source.offer_text
                  .replace(/\|/g, ",")
                  .split(",")[j],
                is_swap_items: popularItemData[i]._source.is_swap_items
                  .replace(/\|/g, ",")
                  .split(",")[j],
                stock_quantities: popularItemData[i]._source.stock_quantities
                  .replace(/\|/g, ",")
                  .split(",")[j],
                pack_ids: popularItemData[i]._source.pack_ids
                  .replace(/\|/g, ",")
                  .split(",")[j],
                pack_names: popularItemData[i]._source.pack_names
                  .replace(/\|/g, ",")
                  .split(",")[j],
                pack_names_without_unit: popularItemData[i]._source.pack_names
                  .replace(/\|/g, ",")
                  .split(",")[j],
                logoPath: popularItemData[i]._source.logo_paths
                  .replace(/\|/g, ",")
                  .split(",")[j],
                logo_path_thumbs: popularItemData[i]._source.logo_path_thumbs
                  .replace(/\|/g, ",")
                  .split(",")[j],
                slug: popularItemData[i]._source.slug
                  .replace(/\|/g, ",")
                  .split(",")[j],
                description: ""
              };
              if (popularItemData[i]._source.displayitemprice === true) {
                if (
                  popularItemData[i]._source.item_prices
                    .replace(/\|/g, ",")
                    .split(",")[j] ===
                  popularItemData[i]._source.mrps.replace(/\|/g, ",").split(",")[j]
                ) {
                  finalDataSetSub[j].is_display_item_price = false;
                  finalDataSetSub[j].mrps = popularItemData[i]._source.mrps
                    .replace(/\|/g, ",")
                    .split(",")[j];
                  finalDataSetSub[j].item_prices = popularItemData[i]._source.mrps
                    .replace(/\|/g, ",")
                    .split(",")[j];
                } else {
                  finalDataSetSub[j].is_display_item_price = true;
                  finalDataSetSub[j].mrps = popularItemData[i]._source.mrps
                    .replace(/\|/g, ",")
                    .split(",")[j];
                  finalDataSetSub[j].item_prices = popularItemData[
                    i
                  ]._source.item_prices
                    .replace(/\|/g, ",")
                    .split(",")[j];
                }
              } else {
                finalDataSetSub[j].is_display_item_price = false;
                finalDataSetSub[j].mrps = popularItemData[i]._source.mrps
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[j].item_prices = popularItemData[i]._source.mrps
                  .replace(/\|/g, ",")
                  .split(",")[j];
              }
            } else {
              if (
                finalDataSetSub[index].item_prices <
                popularItemData[i]._source.item_prices
                  .replace(/\|/g, ",")
                  .split(",")[j]
              ) {
                finalDataSetSub[index].unit = popularItemData[i]._source.unit
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[
                  index
                ].store_item_stock_ids = popularItemData[
                  i
                ]._source.store_item_stock_ids
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[index].unit_type = popularItemData[
                  i
                ]._source.unit_type
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[index].item_ids = popularItemData[
                  i
                ]._source.item_ids
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[index].item_names = popularItemData[
                  i
                ]._source.item_names
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[index].is_offers = popularItemData[
                  i
                ]._source.is_offers
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[index].offer_text = popularItemData[
                  i
                ]._source.offer_text
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[index].is_swap_items = popularItemData[
                  i
                ]._source.is_swap_items
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[index].stock_quantities = popularItemData[
                  i
                ]._source.stock_quantities
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[index].pack_ids = popularItemData[
                  i
                ]._source.pack_ids
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[index].pack_names = popularItemData[
                  i
                ]._source.pack_names
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[
                  index
                ].pack_names_without_unit = popularItemData[i]._source.pack_names
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[index].logoPath = popularItemData[
                  i
                ]._source.logo_paths
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[index].logo_path_thumbs = popularItemData[
                  i
                ]._source.logo_path_thumbs
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[index].slug = popularItemData[i]._source.slug
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[index].description = "";
                if (popularItemData[i]._source.displayitemprice === true) {
                  if (
                    popularItemData[i]._source.item_prices
                      .replace(/\|/g, ",")
                      .split(",")[j] ===
                    popularItemData[i]._source.mrps.replace(/\|/g, ",").split(",")[
                    j
                    ]
                  ) {
                    finalDataSetSub[index].is_display_item_price = false;
                    finalDataSetSub[index].mrps = popularItemData[i]._source.mrps
                      .replace(/\|/g, ",")
                      .split(",")[j];
                    finalDataSetSub[index].item_prices = popularItemData[
                      i
                    ]._source.mrps
                      .replace(/\|/g, ",")
                      .split(",")[j];
                  } else {
                    finalDataSetSub[index].is_display_item_price = true;
                    finalDataSetSub[index].mrps = popularItemData[i]._source.mrps
                      .replace(/\|/g, ",")
                      .split(",")[j];
                    finalDataSetSub[index].item_prices = popularItemData[
                      i
                    ]._source.item_prices
                      .replace(/\|/g, ",")
                      .split(",")[j];
                  }
                } else {
                  finalDataSetSub[index].is_display_item_price = false;
                  finalDataSetSub[index].mrps = popularItemData[i]._source.mrps
                    .replace(/\|/g, ",")
                    .split(",")[j];
                  finalDataSetSub[index].item_prices = popularItemData[
                    i
                  ]._source.mrps
                    .replace(/\|/g, ",")
                    .split(",")[j];
                }
              }
            }
            finalDataSet[i].group_count = group_count;
          }
        } else {
          finalDataSet[i].is_brand_item = false;
          if (popularItemData[i]._source.is_loose_item === true) {
            var availIn = "";
            var variation = popularItemData[i]._source.variation;
            for (var v = 1; v <= variation; v++) {
              if (availIn === "") {
                availIn = (v * popularItemData[i]._source.denomination).toString();
              } else {
                availIn =
                  availIn +
                  "," +
                  (v * popularItemData[i]._source.denomination).toString();
              }
            }
            var availInSplit = availIn.split(",");
            for (var a = 0; a < availInSplit.length; a++) {
              finalDataSetSub[a] = {};
              var packPrice =
                popularItemData[i]._source.item_prices !== null &&
                  popularItemData[i]._source.item_prices !== 0
                  ? (popularItemData[i]._source.item_prices /
                    popularItemData[i]._source.unit) *
                  availInSplit[a]
                  : "-";
              var looseMrp =
                popularItemData[i]._source.mrps !== null &&
                  popularItemData[i]._source.mrps !== 0
                  ? (popularItemData[i]._source.mrps /
                    popularItemData[i]._source.unit) *
                  availInSplit[a]
                  : "-";
              if (
                availInSplit[a] === "1000" &&
                popularItemData[i]._source.unit_type.toLowerCase() === "gm"
              ) {
                finalDataSetSub[a].pack_names = "1 Kg";
                finalDataSetSub[a].pack_names_without_unit = "1000";
              } else if (
                availInSplit[a] === "1000" &&
                popularItemData[i]._source.unit_type.toLowerCase() === "ml"
              ) {
                finalDataSetSub[a].pack_names = "1 Ltr";
                finalDataSetSub[a].pack_names_without_unit = "1000";
              } else {
                finalDataSetSub[a].pack_names =
                  availInSplit[a] + " " + popularItemData[i]._source.unit_type;
                finalDataSetSub[a].pack_names_without_unit = availInSplit[a];
              }
              finalDataSetSub[a].unit = popularItemData[i]._source.unit;
              finalDataSetSub[a].store_item_stock_ids =
                popularItemData[i]._source.store_item_stock_ids;
              finalDataSetSub[a].unit_type = popularItemData[i]._source.unit_type;
              finalDataSetSub[a].item_ids = popularItemData[i]._source.item_ids;
              finalDataSetSub[a].item_names =
                popularItemData[i]._source.item_names;
              finalDataSetSub[a].is_offers = popularItemData[i]._source.is_offers;
              finalDataSetSub[a].offer_text =
                popularItemData[i]._source.offer_text;
              finalDataSetSub[a].is_swap_items =
                popularItemData[i]._source.is_swap_items;
              finalDataSetSub[a].stock_quantities =
                popularItemData[i]._source.stock_quantities;
              finalDataSetSub[a].pack_ids = popularItemData[i]._source.pack_ids;
              finalDataSetSub[a].logoPath = popularItemData[i]._source.logo_paths;
              finalDataSetSub[a].logo_path_thumbs =
                popularItemData[i]._source.logo_path_thumbs;
              finalDataSetSub[a].slug = popularItemData[i]._source.slug;
              finalDataSetSub[a].description = "";
              if (popularItemData[i]._source.displayitemprice === true) {
                if (packPrice.toString() === looseMrp.toString()) {
                  finalDataSetSub[a].is_display_item_price = false;
                  finalDataSetSub[a].mrps = looseMrp;
                  finalDataSetSub[a].item_prices = looseMrp;
                } else {
                  finalDataSetSub[a].is_display_item_price = true;
                  finalDataSetSub[a].mrps = looseMrp;
                  finalDataSetSub[a].item_prices = packPrice;
                }
              } else {
                finalDataSetSub[a].is_display_item_price = false;
                finalDataSetSub[a].mrps = looseMrp;
                finalDataSetSub[a].item_prices = looseMrp;
              }
            }
          } else {
            var j = 0;
            finalDataSetSub[j] = {};
            finalDataSetSub[j].unit = popularItemData[i]._source.unit;
            finalDataSetSub[j].store_item_stock_ids =
              popularItemData[i]._source.store_item_stock_ids;
            finalDataSetSub[j].unit_type = popularItemData[i]._source.unit_type;
            finalDataSetSub[j].item_ids = popularItemData[i]._source.item_ids;
            finalDataSetSub[j].item_names = popularItemData[i]._source.item_names;
            finalDataSetSub[j].is_offers = popularItemData[i]._source.is_offers;
            finalDataSetSub[j].offer_text = popularItemData[i]._source.offer_text;
            finalDataSetSub[j].is_swap_items =
              popularItemData[i]._source.is_swap_items;
            finalDataSetSub[j].stock_quantities =
              popularItemData[i]._source.stock_quantities;
            finalDataSetSub[j].pack_ids = popularItemData[i]._source.pack_ids;
            finalDataSetSub[j].pack_names = popularItemData[i]._source.pack_names;
            finalDataSetSub[j].pack_names_without_unit =
              popularItemData[i]._source.pack_names;
            finalDataSetSub[j].logoPath = popularItemData[i]._source.logo_paths;
            finalDataSetSub[j].logo_path_thumbs =
              popularItemData[i]._source.logo_path_thumbs;
            finalDataSetSub[j].slug = popularItemData[i]._source.slug;
            finalDataSetSub[j].description = "";
            if (popularItemData[i]._source.displayitemprice === true) {
              if (
                popularItemData[i]._source.item_prices.toString() ===
                popularItemData[i]._source.mrps.toString()
              ) {
                finalDataSetSub[j].is_display_item_price = false;
                finalDataSetSub[j].mrps = popularItemData[i]._source.mrps;
                finalDataSetSub[j].item_prices = popularItemData[i]._source.mrps;
              } else {
                finalDataSetSub[j].is_display_item_price = true;
                finalDataSetSub[j].mrps = popularItemData[i]._source.mrps;
                finalDataSetSub[j].item_prices =
                  popularItemData[i]._source.item_prices;
              }
            } else {
              finalDataSetSub[j].is_display_item_price = false;
              finalDataSetSub[j].mrps = popularItemData[i]._source.mrps;
              finalDataSetSub[j].item_prices = popularItemData[i]._source.mrps;
            }
          }
        }
        finalDataSet[i].finalDataSetSub = finalDataSetSub;
        finalDataSet[i].is_display_item_price = popularItemData[i]._source.displayitemprice
        finalDataSet[i].isCartItemColorShow = 'none'
        finalDataSet[i].isCartItemOpenClass = ''
        finalDataSet[i].addcartActive = ''
        if (themeId === '36') {
          finalDataSet[i].cartLabel = '+'
        }
        else if (themeId === '34'|| themeId === '29' || themeId === '4') {
          finalDataSet[i].cartLabel = '<span><img src="https://storedigfiles.s3.amazonaws.com/production/themes/cartBtnLokal.png" /> ADD TO CART</span>'
        }
        else {
          finalDataSet[i].cartLabel = 'ADD TO CART'
        }
        finalDataSet[i].itemAdded = ''
        var cartItems = [];
        if (selectedProduct !== null && selectedProduct.length > 0) {
          for (var cartItem = 0; cartItem < selectedProduct.length; cartItem++) {
            if (selectedProduct[cartItem].store_item_stock_ids.toString() === finalDataSet[i].finalDataSetSub[0].store_item_stock_ids.toString()) {
              cartItems[0] = selectedProduct[cartItem]
              finalDataSet[i].cartItems = cartItems
              finalDataSet[i].isCartItemColorShow = 'block'
              finalDataSet[i].isCartItemOpenClass = 'cart_clk'
              if (themeId === '36') {
                finalDataSet[i].cartLabel = selectedProduct[cartItem].quantity
              }
              else if (themeId === '34'|| themeId === '29' || themeId === '4') {
                finalDataSet[i].cartLabel = '<img src="https://storedigfiles.s3.amazonaws.com/production/themes/cartBtnLokal.png" />'
              }
              else {
                finalDataSet[i].cartLabel = 'ADDED'
              }
              finalDataSet[i].itemAdded = 'itemAdded'
              var data = $.grep(finalDataSetSub, function (e) {
                return parseFloat(e.item_prices) === parseFloat(selectedProduct[cartItem].item_prices);
              });
              var data1 = $.grep(finalDataSetSub, function (e) {
                return parseFloat(e.item_prices) !== parseFloat(selectedProduct[cartItem].item_prices);
              });
              finalDataSet[i].finalDataSetSub = data.concat(data1);
            }
            else {
              finalDataSet[i].cartItems = cartItems
            }
          }
        }
        else {
          finalDataSet[i].cartItems = cartItems
        }
        console.log("finnzlllllllllllllllll",finalDataSet)
        finalDataSetNew.push(finalDataSet)
      }
      this.setState({
        itemResult: finalDataSet,
        isLoading: '0',
        rowCount: totalCount,
        divPagination: '1',
        isData: '1',
        itemOffset: offset
      })
    }
    else {
      this.setState({
        isData: '0'
      })
    }
  }

  componentDidMount() {
    let storeId = window.sessionStorage.getItem('storeId');
    if (storeId) {
      this.itemData()
    }
    else {
      let locationId = localStorage.getItem('locationId');
      let sellerId = window.sessionStorage.getItem('sellerId');
      const postData = {
        functionName: "lokal/storesBySellerIdNonLocation",
        myCategoryId: '',
        locationId: locationId,
        categoryId: '',
        storeId: '',
        sellerId: sellerId
      }
      getData(postData).then((data) => {
        if (data.data.success === "1") {
          window.sessionStorage.setItem('storeFullDetails', JSON.stringify(data.data.result));
          window.sessionStorage.setItem('storeId', data.data.result.storeData[0].storeId)
          window.sessionStorage.setItem('mainCategoryId', data.data.result.storeData[0].mainCategoryId);
          this.itemData()
        }
      });
    }
    var sellerID = "";
    var moduleID = 2;
    var siteCategoryGroupId = "";

    if (window.sessionStorage.getItem('siteCategoryId') !== "0") {
      siteCategoryGroupId = window.sessionStorage.getItem('siteCategoryId');
    }
    else {
      sellerID = window.sessionStorage.getItem('sellerId')
    }
    pageHitAPICall(sellerID, moduleID, siteCategoryGroupId)
    this.loadCommentsFromServer();
    this.setState({
      selected: Number(window.sessionStorage.getItem("ActiveageNumber"))
    })
  }
  loadCommentsFromServer() {
    $.ajax({
      url: this.props.url,
      data: { limit: this.props.perPage, offset: this.state.offset },
      dataType: 'json',
      type: 'GET',
      success: data => {
        this.setState({ data: data.comments, pageCount: Math.ceil(data.meta.total_count / data.meta.limit) });
      },
      error: (xhr, status, err) => {
      }
    });
  }

  itemData() {
    let storeId = window.sessionStorage.getItem('storeId');
    var offset = window.sessionStorage.getItem("offset1");
    var max = "48";
    const postData = {
      functionName: 'searchresult/searchResultItemListing',
      storeId: storeId,
      offset: offset != null ? offset : "0",
      max: max,
    }
    getData(postData).then((data) => {
      if (data.data.success === "1") {
        this.setState({
          imagePath: data.data.result.itemResult[0].imagePath,
          currencySymbol: data.data.result.itemResult[0].currencySymbol,
          cityId: data.data.result.itemResult[0].cityId,
          divPagination: '1',
          isLoading: '1',
          s3AccessKey: data.data.result.itemResult[0].s3bucketAccessKey,
          s3SecretKey: data.data.result.itemResult[0].s3bucketSecretKey
        });
        this.itemListing(offset, max)
      }
    })
  }

  contactForm(storeButtonName, ItemName, storeItemStockId) {
    this.setState({
      storeButtonName: storeButtonName,
      ItemName: ItemName,
      storeItemStockId: storeItemStockId
    })
    $(".contactFormPopUp").show();
  }

  handlePageChange(e) {
    var pageNumber = e.selected + 1;
    window.sessionStorage.setItem("ActiveageNumber", e.selected)
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = "48";
      this.setState({
        activePage: pageNumber,
        isLoading: '0'
      })
    }
    else {
      offset = parseInt(pageNumber - 1) * 48;
      max = "48";
      this.setState({
        activePage: pageNumber,
        isLoading: '0'
      })
    }
    window.sessionStorage.setItem("offset1", offset)
    this.itemListing(offset, max)
    window.scrollTo(0, 0)
    var selected = e.selected;
    var offset = Math.ceil(selected * this.props.perPage);
    this.setState({ offset: offset, selected }, () => {
      this.loadCommentsFromServer();
    });
  }

  componentDidUpdate() {
    $(".saveClose").click(function () {
      this.setState({
        isSaveMore: '0',
      })
      $(".savemorePopUp").hide();
    }.bind(this));
    $(".popOverlay").click(function () {
      this.setState({
        isSaveMore: '0',
      })
      $(".popupWrap").hide();
    }.bind(this));
  }
  saveMore = (no, cityId) => {
    let storeId = window.sessionStorage.getItem('storeId');
    let sisId = $("#sisId" + no).val();
    var itemId = $("#itemId" + no).val();
    var price = $("#price" + no).val();
    var itemName = $("#itemName" + no).text();
    var unit = $("#unit" + no).val();
    this.setState({
      sisId: sisId,
      itemId: itemId,
      price: price,
      itemName: itemName,
      unit: unit,
      cityId: cityId,
      storeId: storeId,
      isSaveMore: '1',
      isLoading: '0'

    });
    $(".savemorePopUp").show();
  }

  setValueOnLoosePackChange = (storeId, no, mrp, price, unit, currencySymbol, variations, event) => {
    var pno = $("#selPack" + no).val();
    $("#hidPackIndex" + no).val(pno)
    var storeItemStockId = $("#sisId" + pno).val();
    var itemName = $("#itemName" + pno).val();
    var itemId = $("#itemId" + pno).val();
    var stockQuantity = $("#itemStockQuantity" + pno).val();
    var netAmount = $("#price" + pno).val();
    var packId = $("#packId" + pno).val();
    var mrp = $("#mrp" + pno).val();
    var image = $("#image" + pno).val();
    $("#itemPricespan" + no).text(currencySymbol + " " + parseFloat(netAmount).toFixed(window.sessionStorage.getItem('decimalCount')));
    $("#itemMrpspan" + no).text(currencySymbol + " " + parseFloat(mrp).toFixed(window.sessionStorage.getItem('decimalCount')));
    this.refs.groceryCart.updateCart(itemName, itemId, $("#txtQty" + no).val(), netAmount, storeItemStockId, packId, false, stockQuantity, image)
  }

  setValueOnPackChange = (no, sisId, itemId, price, packId, mrp, logoPath, stockQuantity, swapItem, itemPath, itemSrcPath, unit, isoffer, offerText, currencySymbol, event,ind, itemoffset,is_display_item_price) => {
    var pno = $("#selPack" + no).val();
    var index = no + '-' + pno
    $("#hidPackIndex" + no).val(index)
    $("#sisId" + no).val(sisId.split('|')[pno]);
    $("#itemId" + no).val(itemId.split('|')[pno]);
    $("#price" + no).val(price.split('|')[pno]);
    $("#unit" + no).val(unit.split('|')[pno]);
    $("#packId" + no).val(packId.split('|')[pno]);
    $("#itemMrp" + no).val(mrp.split('|')[pno]);
    var cartPrice = parseFloat(price.split('|')[pno]).toFixed(window.sessionStorage.getItem('decimalCount'))
    var cartPackId = packId.split('|')[pno]
    var itemId = itemId.split('|')[pno]
    var itemImage = itemSrcPath + '/' + itemPath.split('|')[pno]
    var sisId = sisId.split('|')[pno]
    if(is_display_item_price === true){ 
    $("#itemPricespan" + no).text(currencySymbol + " " + parseFloat(price.split('|')[pno]).toFixed(window.sessionStorage.getItem('decimalCount')));
    $("#itemMrpspan" + no).text(currencySymbol + " " + parseFloat(mrp.split('|')[pno]).toFixed(window.sessionStorage.getItem('decimalCount')));
    }
    else {
      $("#itemPricespan" + no).text(currencySymbol + " " + parseFloat(mrp.split('|')[pno]).toFixed(window.sessionStorage.getItem('decimalCount')));
    }
    $("#itemStockQuantity" + no).text(stockQuantity.split('|')[pno]);
    $("#image" + no).attr("src", itemSrcPath + '/' + itemPath.split('|')[pno]);
    if (swapItem.split('|')[pno] === "1") {
      $("#swap" + no).show();
    }
    else {
      $("#swap" + no).hide();
    }
    if (isoffer.split('|')[pno] === "1") {
      $("#offerSpan" + no).show();

    }
    else {
      $("#offerSpan" + no).hide();

    }
  }
  addDefaultSrc(ev) {
    ev.target.src = ProductDefault
  }
  render() {
    $(".popOverlay").click(function () {
      $(".popupWrap").hide();
    });
    let itemOffset;
    itemOffset = this.state.itemOffset;
    return (
      <div>
        <div>
          {this.state.itemResult ?
            <div >
              {console.log("this.state.itemResultthis.state.itemResultthis.state.itemResultthis.state.itemResult",this.state.itemResult)}
              {this.state.itemResult.map((itemresult, index) => (
                <div className="productList" key={'item' + index}>
                  {itemresult.finalDataSetSub.map((pack_names, packIndex) => (
                    <div>
                      <input type="hidden" id={"sisId" + (index + itemOffset + '-' + packIndex)} value={pack_names.store_item_stock_ids} />
                      <input type="hidden" id={"itemId" + (index + itemOffset + '-' + packIndex)} value={pack_names.item_ids} />
                      <input type="hidden" id={"price" + (index + itemOffset + '-' + packIndex)} value={pack_names.item_prices} />
                      <input type="hidden" id={"unit" + (index + itemOffset + '-' + packIndex)} value={pack_names.unit} />
                      <input type="hidden" id={"packId" + (index + itemOffset + '-' + packIndex)} value={pack_names.pack_ids} />
                      <input type="hidden" id={"itemStockQuantity" + (index + itemOffset + '-' + packIndex)} value={pack_names.stock_quantities} />
                      <input type="hidden" id={"itemName" + (index + itemOffset + '-' + packIndex)} value={pack_names.item_names} />
                      <input type="hidden" id={"mrp" + (index + itemOffset + '-' + packIndex)} value={pack_names.mrps} />
                      <input type="hidden" id={"image" + (index + itemOffset + '-' + packIndex)} value={this.state.imagePath + '/' + pack_names.logo_path_thumbs} />
                    </div>
                  ))}
                  <input type="hidden" id={"sisId" + parseInt(index + itemOffset)} value={itemresult.finalDataSetSub[0].store_item_stock_ids} />
                  <input type="hidden" id={"itemId" + parseInt(index + itemOffset)} value={itemresult.finalDataSetSub[0].item_ids} />
                  <input type="hidden" id={"price" + parseInt(index + itemOffset)} value={itemresult.finalDataSetSub[0].item_prices} />
                  <input type="hidden" id={"unit" + parseInt(index + itemOffset)} value={itemresult.finalDataSetSub[0].unit} />
                  <input type="hidden" id={"packId" + parseInt(index + itemOffset)} value={itemresult.finalDataSetSub[0].pack_ids} />
                  <input type="hidden" id={"itemStockQuantity" + parseInt(index + itemOffset)} value={itemresult.finalDataSetSub[0].stock_quantities} />
                  <div className="productItem">
                    {itemresult.finalDataSetSub[0].is_offers === "1" ?
                      <span id={'offerSpan' + parseInt(index + itemOffset)} className="offerText"><img src={offer} /> {itemresult.finalDataSetSub[0].offer_text}</span>
                      : ""}
                    <div>
                      <div className="productImage">
                        <Link to={{ pathname: "/" + itemresult.finalDataSetSub[0].slug }}>
                          <img onError={this.addDefaultSrc} id={'image' + parseInt(index + itemOffset)} alt={itemresult.finalDataSetSub[0].item_names} src={this.state.imagePath + '/' + itemresult.finalDataSetSub[0].logo_path_thumbs} />
                        </Link>
                      </div>
                      <div className="productDetails">
                        <div className="brandName">{itemresult.brand_name}</div>
                        <div className="productName">
                          <NavLink className="elipsis" id={'itemNameTitle' + parseInt(index + itemOffset)} title={itemresult.finalDataSetSub[0].item_names} to={{ pathname: "/" + itemresult.finalDataSetSub[0].slug }}>{itemresult.finalDataSetSub[0].item_names}</NavLink>
                        </div>
                        <div className="productQty">
                          <div>
                            <input type="hidden" id={"hidPackIndex" + parseInt(index + itemOffset)} value={index + itemOffset + '-' + 0} />
                            {(() => {
                              if (itemresult.is_loose_item === true) {
                                return (
                                  <select id={'selPack' + parseInt(index + itemOffset)} onChange={(e) => this.setValueOnLoosePackChange(itemresult.store_id, parseInt(index + itemOffset), itemresult.finalDataSetSub[itemresult.finalDataSetSub.length - 1].mrps, itemresult.finalDataSetSub[itemresult.finalDataSetSub.length - 1].item_prices, itemresult.finalDataSetSub[itemresult.finalDataSetSub.length - 1].unit, this.state.currencySymbol, itemresult.finalDataSetSub.length, e)}>
                                    {itemresult.finalDataSetSub.map((pack_names, packIndex) => (
                                      <option value={index + itemOffset + '-' + packIndex} key={pack_names.pack_names + ' ' + this.state.currencySymbol + ' ' + parseFloat(pack_names.item_prices).toFixed(window.sessionStorage.getItem('decimalCount'))}>{pack_names.pack_names} - {this.state.currencySymbol} {parseFloat(pack_names.item_prices).toFixed(window.sessionStorage.getItem('decimalCount'))}</option>
                                    ))}
                                  </select>
                                )
                              }
                              else if (itemresult.group_count === '1') {
                                return (
                                  <div>
                                    {itemresult.finalDataSetSub.map((pack_names) => (
                                      pack_names.pack_names
                                    ))}
                                  </div>
                                )
                              }
                              else {
                                return (
                                  <select id={'selPack' + parseInt(index + itemOffset)} onChange={(e) => this.setValueOnPackChange(parseInt(index + itemOffset), itemresult.store_item_stock_ids_with_seperator, itemresult.item_ids_with_seperator, itemresult.item_prices_with_seperator, itemresult.pack_ids_with_seperator, itemresult.mrps_with_seperator, itemresult.logoPath_with_seperator, itemresult.stock_quantities_with_seperator, itemresult.is_swap_items_with_seperator, itemresult.logo_path_thumbs_with_seperator, this.state.imagePath, itemresult.unit_with_seperator, itemresult.is_offers_with_seperator, itemresult.offer_text_with_seperator, this.state.currencySymbol, e, index, itemOffset,itemresult.is_display_item_price)}>
                                    {itemresult.finalDataSetSub.map((pack_names, packno) => (
                                      <option value={packno}>{pack_names.pack_names} - {this.state.currencySymbol} {parseFloat(pack_names.item_prices).toFixed(window.sessionStorage.getItem('decimalCount'))}</option>
                                    ))}
                                  </select>
                                )
                              }
                            })()}
                          </div>
                        </div>
                        <div className="productPrice">
                          {console.log("itemresultitemresultitemresultitemresultitemresult",itemresult)}
                          {itemresult.hasContactUsWithoutPrice === false ?
                            <>
                              {itemresult.is_display_item_price === true ?
                                <div>
                                  {itemresult.finalDataSetSub[0].item_prices > 0 ?
                                    <span id={'itemPricespan' + parseInt(index + itemOffset)} className="newPrice">{this.state.currencySymbol} {parseFloat(itemresult.finalDataSetSub[0].item_prices).toFixed(window.sessionStorage.getItem('decimalCount'))} </span>
                                    : ""}
                                  {itemresult.finalDataSetSub[0].mrps > itemresult.finalDataSetSub[0].item_prices ?
                                    <span style={{display:((itemresult.finalDataSetSub[0].mrps > itemresult.finalDataSetSub[0].item_prices) ? 'block' : 'none')}} id={'itemMrpspan' + parseInt(index + itemOffset)} className="oldPrice">{this.state.currencySymbol}  {parseFloat(itemresult.finalDataSetSub[0].mrps).toFixed(window.sessionStorage.getItem('decimalCount'))}</span>
                                     : ""} 
                                </div> :
                                <div>
                                  {itemresult.finalDataSetSub[0].item_prices > 0 ?
                                    <span id={'itemPricespan' + parseInt(index + itemOffset)} className="newPrice">{this.props.currencySymbol} {parseFloat(itemresult.finalDataSetSub[0].mrps).toFixed(window.sessionStorage.getItem('decimalCount'))} </span>
                                    : ""}
                                </div>
                              }
                            </>
                            : ""}
                        </div>
                        <div className="itemCountNumber"></div>
                        <div className="addCartButton">
                          {(() => {
                            
                            if (itemresult.finalDataSetSub[0].is_swap_items === true) {
                              if (itemresult.hasBuyOption) {
                                return (
                                  <div id={'swap' + parseInt(index + itemOffset)} className="saveMore" onClick={(e) => this.saveMore(parseInt(index + itemOffset), this.state.cityId)}>Save more</div>
                                )
                              }
                            }
                          })()}
                          {(() => {
                            if (itemresult.hasContactUsWithoutPrice === true) {
                              if (itemresult.storeButtonName) {
                                return (
                                  <span class="cartButton" onClick={(e) => this.contactForm(itemresult.storeButtonName, itemresult.finalDataSetSub[0].item_names, itemresult.finalDataSetSub[0].store_item_stock_ids)}>{itemresult.storeButtonName}</span>
                                )
                              }
                              else {
                                return (
                                  <span class="cartButton" onClick={(e) => this.contactForm(itemresult.storeButtonName, itemresult.finalDataSetSub[0].item_names, itemresult.finalDataSetSub[0].store_item_stock_ids)}>Contact Us</span>
                                )
                              }
                            }
                            else if (itemresult.hasBuyOption === true) {
                              if (itemresult.finalDataSetSub[0].mrps !== 0 || itemresult.finalDataSetSub[0].mrps <= 0) {
                                return (
                                  <CartBtn
                                    itemLoad={this.itemLoad}
                                    index={parseInt(index + itemOffset)}
                                    itemOffset={itemOffset}
                                    item_names={itemresult.finalDataSetSub[0].item_names}
                                    item_ids={(() => {
                                      if (itemresult.is_loose_item === true) {
                                        return (
                                          itemresult.finalDataSetSub[0].item_ids
                                        )
                                      }
                                      else if (itemresult.group_count === '1') {
                                        return (
                                          itemresult.finalDataSetSub[0].item_ids
                                        )
                                      }
                                      else {
                                        if (this.state.itemIdForSession === "") {
                                          return (
                                            itemresult.finalDataSetSub[0].item_ids
                                          )
                                        }
                                        else {
                                          return (
                                            this.state.itemIdForSession
                                          )
                                        }
                                      }
                                    })()}
                                    item_prices={(() => {
                                      if (itemresult.is_loose_item === true) {
                                        if (this.state.itemPriceForSession === "") {
                                          return (
                                            itemresult.finalDataSetSub[0].item_prices
                                          )
                                        }
                                        else {
                                          return (
                                            this.state.itemPriceForSession
                                          )
                                        }
                                      }
                                      else if (itemresult.group_count === '1') {
                                        return (
                                          itemresult.finalDataSetSub[0].item_prices
                                        )
                                      }
                                      else {
                                        if (this.state.itemPriceForSession === "") {
                                          return (
                                            itemresult.finalDataSetSub[0].item_prices
                                          )
                                        }
                                        else {
                                          return (
                                            this.state.itemPriceForSession
                                          )
                                        }
                                      }
                                    })()}
                                    store_item_stock_ids={(() => {
                                      if (itemresult.is_loose_item === true) {
                                        return (
                                          itemresult.finalDataSetSub[0].store_item_stock_ids
                                        )
                                      }
                                      else if (itemresult.group_count === '1') {
                                        return (
                                          itemresult.finalDataSetSub[0].store_item_stock_ids
                                        )
                                      }
                                      else {
                                        if (this.state.sisIdForSession === "") {
                                          return (
                                            itemresult.finalDataSetSub[0].store_item_stock_ids
                                          )
                                        }
                                        else {
                                          return (
                                            this.state.sisIdForSession
                                          )
                                        }


                                      }
                                    })()}
                                    pack_ids={(() => {
                                      if (itemresult.is_loose_item === true) {
                                        return (
                                          itemresult.finalDataSetSub[0].pack_ids
                                        )
                                      }
                                      else if (itemresult.group_count === '1') {
                                        return (
                                          itemresult.finalDataSetSub[0].pack_ids
                                        )
                                      }
                                      else {
                                        if (this.state.itemPackIdForSession === "") {
                                          return (
                                            itemresult.finalDataSetSub[0].pack_ids
                                          )
                                        }
                                        else {
                                          return (
                                            this.state.itemPackIdForSession
                                          )
                                        }

                                      }
                                    })()}
                                    stock_quantity={itemresult.finalDataSetSub[0].stock_quantities}
                                    itemAddedColour="itemAddedColour"
                                    qtyError="qtyError"
                                    itemCount="itemCount"
                                    cartLabel={itemresult.cartLabel}
                                    addcartActive={itemresult.addcartActive}
                                    isCartItemOpenClass={itemresult.isCartItemOpenClass}
                                    cartItemFlag={itemresult.cartItems.length > 0 ? true : false}
                                    cartItemQuantity={itemresult.cartItems.length > 0 ? itemresult.cartItems[0].quantity : 1}
                                    itemImage={(() => {
                                      if (itemresult.is_loose_item === true) {
                                        return (
                                          this.state.imagePath + '/' + itemresult.finalDataSetSub[0].logo_path_thumbs
                                        )
                                      }
                                      else if (itemresult.group_count === '1') {
                                        return (
                                          this.state.imagePath + '/' + itemresult.finalDataSetSub[0].logo_path_thumbs
                                        )
                                      }
                                      else {
                                        if (this.state.itemImageForSession === "") {
                                          return (
                                            this.state.imagePath + '/' + itemresult.finalDataSetSub[0].logo_path_thumbs
                                          )
                                        }
                                        else {
                                          return (
                                            this.state.itemImageForSession
                                          )
                                        }

                                      }
                                    })()}
                                    ref="groceryCart"
                                  ></CartBtn>
                                )
                              }
                              else {
                                if (itemresult.storeButtonName) {
                                  return (
                                    <span class="cartButton" onClick={(e) => this.contactForm(itemresult.storeButtonName, itemresult.finalDataSetSub[0].item_names, itemresult.finalDataSetSub[0].store_item_stock_ids)}>{itemresult.storeButtonName}</span>
                                  )
                                }
                                else {
                                  return (
                                    <span class="cartButton" onClick={(e) => this.contactForm(itemresult.storeButtonName, itemresult.finalDataSetSub[0].item_names, itemresult.finalDataSetSub[0].store_item_stock_ids)}>Contact Us</span>
                                  )
                                }
                              }
                            }
                            else if (itemresult.hasBuyOption === false) {
                              if (itemresult.storeButtonName) {

                                return (
                                  <span class="cartButton" onClick={(e) => this.contactForm(itemresult.storeButtonName, itemresult.finalDataSetSub[0].item_names, itemresult.finalDataSetSub[0].store_item_stock_ids)}>{itemresult.storeButtonName}</span>
                                )
                              }
                              else {
                                return (
                                  <span class="cartButton" onClick={(e) => this.contactForm(itemresult.storeButtonName, itemresult.finalDataSetSub[0].item_names, itemresult.finalDataSetSub[0].store_item_stock_ids)}>Contact Us</span>
                                )
                              }
                            }
                          })()}
                        </div>
                      </div>
                      {(() => {
                        if (itemresult.cartItems.length === 0) {
                          return (
                            <div>
                              <div className="showItemDisplayColor" id={"itemAddedColour" + parseInt(index + itemOffset)}><span className="itemAddCount" id={"itemCount" + parseInt(index + itemOffset)}>{ }</span></div>
                              <div className="itemAlertText" id={'qtyError' + parseInt(index + itemOffset)}></div>
                            </div>
                          )
                        }
                        else {
                          return (
                            <div>
                              <div className={"showItemDisplayColor itemAdded itemDisplay " + itemresult.itemAdded} id={"itemAddedColour" + parseInt(index + itemOffset)}><span className="itemAddCount" id={"itemCount" + parseInt(index + itemOffset)}>{itemresult.cartItems[0].quantity}</span></div>
                              <div className="itemAlertText" id={'qtyError' + parseInt(index + itemOffset)}></div>
                            </div>
                          )
                        }
                      })()}
                    </div>
                  </div>
                  <div>
                  </div>
                </div>
              ))}

              <div class="popupWrap contactFormPopUp">
                <Contact storeButtonName={this.state.storeButtonName} ItemName={this.state.ItemName} storeItemStockId={this.state.storeItemStockId}></Contact>
              </div>
              {this.state.rowCount > 48 ?
                <div className="paginationSection" id="divPagination">
                  {this.state.divPagination === '1' ?
                    <ReactPaginate
                      nextLabel=">>"
                      onPageChange={this.handlePageChange}
                      pageRangeDisplayed={10}
                      marginPagesDisplayed={1}
                      pageCount={this.state.rowCount / 48}
                      previousLabel="<<"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                      forcePage={this.state.selected} />
                    : ''}
                </div>
                : ""}
              <div>
                <div class="popupWrap savemorePopUp">
                  {this.state.isSaveMore === '1' ?
                    <Savemore
                      sisId={this.state.sisId}
                      itemId={this.state.itemId}
                      price={this.state.price}
                      unit={this.state.unit}
                      cityId={this.state.cityId}
                      storeId={this.state.storeId}
                      flag='1'
                    >
                    </Savemore>
                    : ''}
                </div>
              </div>
            </div>
            : ""}
        </div>
      </div>
    )
  }
};