import React, { Component, Suspense  } from 'react'
 let Home = React.lazy(() => import("../" +  localStorage.getItem("themeName") + "/home"));
 let Header = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/header"));
 let Footer = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/footer"));
export default class LoaderCopy extends Component {
  state = {
    css: null,
  };
  componentDidMount(){
      const themeName = localStorage.getItem("themeName");
    //  const themeName = 'luna';
    const css = themeName ? themeName + 'Wrapper' : null;
    this.setState({ css });
    console.log("theme", themeName, css);
  }
  render() { 
    const { css } = this.state;
    if (css === null) {
      return null; // Render nothing if css is null
    }
    return (
      <div>
        <Suspense fallback={<div></div>}>
            <section className= {css} >
              <Header />
              <Home /> 
             <Footer /> 
            </section>
          </Suspense>        
      </div>
    );
  }
}