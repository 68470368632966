import React, { Component, Suspense } from 'react'
import StoreProfileGrocery from "./storeProfileGrocery"
import StoreProfileLuna from "../luna/store-profile"
import StoreProfileCommonItem from "./storeProfileCommonItem"
import StoreProfileRestaurant from "../restaurant/restaurantListingSubdomain"
import Storebanner from "./storeBanner"
import Loader from "../headerComponents/loader"
import { setData } from '../setDefaultData'
import { getData } from '../services';
const subDomainFlag = window.sessionStorage.getItem('subDomainFlag')
let Header = (window.sessionStorage.getItem('subDomainFlag') === "1" ? "" : React.lazy(() => import("../" + localStorage.getItem("themeName") + "/header")));
let Footer = (window.sessionStorage.getItem('subDomainFlag') === "1" ? "" : React.lazy(() => import("../" + localStorage.getItem("themeName") + "/footer")));
export default class StoreProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: '0'
        };
    }
    async componentDidMount() {      
        console.log('#########..1..subDomainFlag.sessionStorage..',window.sessionStorage.getItem('subDomainFlag'));
        // console.log('#########..2..subDomainFlag...',this.props.match.params.storeName);
        let storeId = window.sessionStorage.getItem('storeId');
        if (storeId === null || storeId === "null" || storeId === "") {
            await setData.call();

            let storeIdFromUrl = ""
            if (typeof this.props.match.params.storeName !== "undefined") {
                let urlData = this.props.match.params.storeName.split('-')
                storeIdFromUrl = urlData[urlData.length - 1]
            }
            else {
                let urlData = this.props.match.params.slug.split('-')
                storeIdFromUrl = urlData[0]
            }
            const postData = {
                functionName: "common/getStoreDataWithStoreId",
                storeId: storeIdFromUrl,
            }
            console.log('#########..3..postdata...',postData);
            getData(postData).then((data) => {
                console.log('#########..4..data...',data);
                if (data.data.success === "1") {
                    localStorage.setItem('locationId', data.data.data[0].locationId);
                    localStorage.setItem('locationText', data.data.data[0].locationName)
                    localStorage.setItem('locationNameOnly', data.data.data[0].locationName)
                    window.sessionStorage.setItem('storeId', data.data.data[0].storeId)
                    window.sessionStorage.setItem('storeName', data.data.data[0].storeName)
                    window.sessionStorage.setItem('mainGroupCategory', data.data.data[0].main_group_category_id)
                    window.sessionStorage.setItem('mainCategoryId', data.data.data[0].main_category_id);
                    window.sessionStorage.setItem('mainCategoryIdLokal', data.data.data[0].main_category_id)
                    localStorage.setItem('cityId', data.data.data[0].cityId)
                    localStorage.setItem('cityText', data.data.data[0].cityName)
                    localStorage.setItem('cityNameOnly', data.data.data[0].cityName)
                    window.sessionStorage.setItem('storeMobile', data.data.data[0].mobileNumber);
                    window.sessionStorage.setItem('storeEmail', data.data.data[0].emailId);
                    if (data.data.data[0].main_group_category_id == '8') {
                        window.location.href = "/cmItemListing/0/0/0"
                    }
                    else {
                        window.location.reload()
                    }
                }
            })
        }
        else {
            this.setState({
                isLoading: '1'
            })
        }
    }
    render() {
        let themeId = localStorage.getItem('themeId')
        let mainGroupCategory = window.sessionStorage.getItem('mainGroupCategory')
        return (
            this.state.isLoading === '0' ?
                <Loader></Loader>
                :
                // <div className={mainGroupCategory === '1' && "coverWraper"}>
                // {mainGroupCategory === '1' && <Header />}

                <div className={mainGroupCategory === '1' && themeId === "29" ? "coverWraper mraCoverWraper" : "coverWraper"}>
                    <Suspense fallback={<div></div>}>
                        {/* {console.log("testssssssssss",mainGroupCategory,window.sessionStorage.getItem('subDomainFlag'))} */}
                    {window.sessionStorage.getItem('subDomainFlag') !== "1" && <Header />}
                    {/* {mainGroupCategory === '1' && themeId === "29" ? <MraHeader /> : <Header />} */}
                    <div className={mainGroupCategory === '1' && "contentWrap"}>
                    {/* <div className={mainGroupCategory === '1' && themeId === "34" ? "contentWrap subDomainWrap" : "contentWrap"}> */}
                        {(mainGroupCategory === '1'&& themeId !== '4') && <Storebanner />}
                        {(() => {
                           if (mainGroupCategory === '1' && themeId === '4') {
                            return (
                                <StoreProfileLuna />
                            )
                        }
                            if (mainGroupCategory === '1') {
                                return (
                                    <StoreProfileGrocery />
                                )
                            }
                            else if (mainGroupCategory === '8') {
                                return (
                                    <StoreProfileCommonItem />
                                )
                            }
                            else if (mainGroupCategory === '5') {
                                return (
                                    <StoreProfileRestaurant />
                                );
                            }
                        })()}
                    </div>


                    {/* {mainGroupCategory !== '5' ? <Footer /> : ''} */}
                    {/* {mainGroupCategory !== '5' && themeId === "29" ? <MraFooter /> : <Footer />} */}
                    {window.sessionStorage.getItem('subDomainFlag') !== "1" && <Footer />}
                    </Suspense>
                </div >
        )
    }
}