import React, { Component , Suspense} from 'react';
import $ from "jquery";
import { getData, pageHitAPICall } from '../services';
import { Link } from 'react-router-dom';
// import Header from "../lokalIn/header"
// import Footer from "../lokalIn/footer"
// import MraHeader from "../mraTheme/header"
// import MraFooter from "../mraTheme/footer";
import map from '../images/lokal/map.png'
import locImg from '../images/locImg.png'
import { Helmet } from "react-helmet-async";
import { setData } from '../setDefaultData'
import Loader from "../headerComponents/loader"
import Storelisting from "../luna/store-listing"
let Header = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/header"));
let Footer = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/footer"));
var themeId = localStorage.getItem('themeId');
export default class StoreLokal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            store: [],
            storeGlobal: [],
            isData: '0',
            isLoading: '0'
        }
    }
    async componentDidMount() {
        let locationId = localStorage.getItem('locationId');
        let categoryId = window.sessionStorage.getItem('mainCategoryIdLokal');
        if (((localStorage.getItem('siteGlobalDelivery') === "false"  && localStorage.getItem('isPincodeBased') === "false")&& ((locationId === null || locationId === "null" || locationId === "") || (categoryId === null || categoryId === "null" || categoryId === "")))) {
            await setData.call()
            var slug = this.props.match.params.slug
            var slugSplit = slug.split("-")
            const postData = {
                functionName: "common/getLocationAndCategoryData",
                categoryName: slugSplit[0],
                loactionName: slug.split('-delivery-in-')[1].replace(/-/g, " ")
            }
            getData(postData).then((data) => {
                console.log('data.....common/getLocationAndCategoryData......',data)
                if (data.data.success === "1") {
                    localStorage.setItem('locationId', data.data.result.location[0].locationId);
                    localStorage.setItem('locationText', data.data.result.location[0].locationName)
                    localStorage.setItem('locationNameOnly', data.data.result.location[0].locationName)
                    window.sessionStorage.setItem('mainGroupCategory', data.data.result.category[0].mainGroupCategoryId)
                    window.sessionStorage.setItem('mainCategoryId', data.data.result.category[0].mainCategoryId);
                    window.sessionStorage.setItem('mainCategoryIdLokal', data.data.result.category[0].mainCategoryId)
                    window.sessionStorage.setItem('mainCategoryAlternativeName', data.data.result.category[0].alternativeName)
                    localStorage.setItem('cityId', data.data.result.location[0].cityId)
                    localStorage.setItem('cityText', data.data.result.location[0].cityName)
                    localStorage.setItem('cityNameOnly', data.data.result.location[0].cityName)
                    let title = data.data.result.category[0].seoTitle.replace('{{SiteName}}', window.sessionStorage.getItem('siteName')).replace('{{Location}}', localStorage.getItem('locationNameOnly'))
                    let description = data.data.result.category[0].seoDescription.replace('{{SiteName}}', window.sessionStorage.getItem('siteName')).replace('{{Location}}', localStorage.getItem('locationNameOnly'))
                    let slug = data.data.result.category[0].seoSlug.replace('{{City}}', localStorage.getItem('cityNameOnly').toLowerCase()).replace('{{Location}}', data.data.result.location[0].locationName.toLowerCase().trim().replace(/ /g, '-'))
                    let h1 = data.data.result.category[0].seoH1.replace('{{Location}}', localStorage.getItem('locationNameOnly'))
                    window.sessionStorage.setItem('mainCategorySeoTitle', title)
                    window.sessionStorage.setItem('mainCategorySeoDescription', description)
                    window.sessionStorage.setItem('mainCategorySeoSlug', slug)
                    window.sessionStorage.setItem('mainCategorySeoH1', h1)
                    window.location.reload()
                }
            })
        }
        else {
            window.scrollTo(0, 0)
            window.sessionStorage.setItem("offset1", 0)
            window.sessionStorage.setItem("offset2", 0)
            window.sessionStorage.setItem("ActiveageNumber", 0)
            window.sessionStorage.setItem("ActiveageNumber2", 0)
            var mainCategoryIdBeforLocation = window.sessionStorage.getItem('mainCategoryIdBeforLocation')
            if (mainCategoryIdBeforLocation !== null && mainCategoryIdBeforLocation !== '' && mainCategoryIdBeforLocation !== 'null') {
                categoryId = mainCategoryIdBeforLocation
                window.sessionStorage.setItem('mainCategoryIdLokal', categoryId)
                window.sessionStorage.setItem('mainCategoryId', categoryId)
            }
            const postData = {
                "functionName": "lokal/store",
                "myCategoryId": window.sessionStorage.getItem('siteCategoryId'),
                "locationId": locationId !== null ?locationId :"" ,
                "categoryId": categoryId
            }
            getData(postData).then((data) => {
                console.log("test",data.data.result)
                if (data.data.success === "1") {
                    if (data.data.result.storeData.length > 0 || data.data.result.storeGlobalResults.length > 0) {
                        window.sessionStorage.setItem('mainCategoryIdBeforLocation', '')
                        data.data.result.storeData = data.data.result.storeData.map((obj) => {
                            
                            let url = window.sessionStorage.getItem('mainCategoryAlternativeName').toLowerCase() + '-shop-in-' + "anywhere" + '-' + obj.storeName.toLowerCase().trim().replace(/ /g, '-') + '-' + obj.storeId
                           if(localStorage.getItem('siteGlobalDelivery') === "false" && localStorage.getItem('isPincodeBased') === "false"){
                             url = window.sessionStorage.getItem('mainCategoryAlternativeName').toLowerCase() + '-shop-in-' + localStorage.getItem('locationNameOnly').toLowerCase().trim().replace(/ /g, '-') + '-' + obj.storeName.toLowerCase().trim().replace(/ /g, '-') + '-' + obj.storeId
                           }
                            obj.slug = url
                            return obj;
                        })
                        data.data.result.storeGlobalResults = data.data.result.storeGlobalResults.map((obj) => {
                            let url = window.sessionStorage.getItem('mainCategoryAlternativeName').toLowerCase() + '-shop-in-' + "anywhere" + '-' + obj.storeName.toLowerCase().trim().replace(/ /g, '-') + '-' + obj.storeId
                            if(localStorage.getItem('siteGlobalDelivery') === "false"  && localStorage.getItem('isPincodeBased') === "false"){
                             url = window.sessionStorage.getItem('mainCategoryAlternativeName').toLowerCase() + '-shop-in-' + localStorage.getItem('locationNameOnly').toLowerCase().trim().replace(/ /g, '-') + '-' + obj.storeName.toLowerCase().trim().replace(/ /g, '-') + '-' + obj.storeId
                            }
                           
                            obj.slug = url
                            return obj;
                        })

                        this.setState({
                            store: data.data.result.storeData,
                            storeGlobal: data.data.result.storeGlobalResults,
                            isData: '1',
                            isLoading: '1'
                        });
                    }
                    else {
                        this.setState({
                            store: data.data.result.storeData,
                            storeGlobal: data.data.result.storeGlobalResults,
                            isData: '0',
                            isLoading: '1'
                        });
                    }
                    $(".categoryPopUp").hide();
                }
                else {
                    this.setState({
                        isData: '0',
                        isLoading: '1'
                    });
                }
            });
            pageHitAPICall("", 10, window.sessionStorage.getItem('siteCategoryId'));
        }
    }
    setStore(storeObj) {
        window.sessionStorage.setItem('storeName', storeObj.storeName)
        window.sessionStorage.setItem('storeId', storeObj.storeId)
        window.sessionStorage.setItem('mainGroupCategory', storeObj.mainGroupCategory)
        localStorage.setItem('cityId', storeObj.cityId);
        window.sessionStorage.setItem('storeMobile', storeObj.mobileNumber);
        window.sessionStorage.setItem('storeEmail', storeObj.emailId);
    }
    locationClick() {
        $(".locationPopUp").show();
    }
    addDefaultSrc(ev) {
        ev.target.src = $("#hid" + ev.target.id).val()
    }
    render() {
        let locationSessionName = localStorage.getItem('locationNameOnly');
        let mainCategoryId = window.sessionStorage.getItem('mainCategoryId');
        let seoDesc = ""
        if (mainCategoryId !== null && mainCategoryId !== "null" && mainCategoryId !== "") {
            seoDesc = window.sessionStorage.getItem('mainCategorySeoDescription')
        }
        return (
            <div>
                {this.state.isLoading === '0' ? <Loader></Loader> :
                    <div className="coverWraper">
                        <Suspense fallback={<div></div>}>
                        <Header></Header>
                          {/* {themeId === '29' ? <MraHeader></MraHeader> : <Header></Header>} */}
                        <Helmet>
                            <link rel="canonical" href={window.sessionStorage.getItem('storeUrl')} />
                            <title>{window.sessionStorage.getItem('mainCategorySeoTitle')}</title>
                            <meta name="description" content={seoDesc} />
                            <meta name="keywords" content={window.sessionStorage.getItem("seoKeyword")} ></meta>
                            <meta name="twitter:title" content={window.sessionStorage.getItem("seoTitle")}></meta>
                            <meta name="twitter:description" content={seoDesc}></meta>
                            <meta name="twitter:image:src" content={window.sessionStorage.getItem("seoImage")}></meta>
                            <meta name="facebook:title" content={window.sessionStorage.getItem("seoTitle")}></meta>
                            <meta name="facebook:description" content={seoDesc}></meta>
                            <meta name="facebook:image:src" content={window.sessionStorage.getItem("seoImage")}></meta>
                            <meta property='og:type' content='website' />
                            <meta property="og:title" content={window.sessionStorage.getItem("seoTitle")} ></meta>
                            <meta property="og:image" content={window.sessionStorage.getItem("seoImage")} ></meta>
                            <meta property="og:description" content={seoDesc} ></meta>
                            <meta property='og:site_name' content={window.sessionStorage.getItem("siteName")} />
                            <meta property="og:url" content={window.sessionStorage.getItem("seoUrl")} ></meta>
                        </Helmet>
                        <div className={themeId == 29 ? "mraContentWrap contentWrap" :"contentWrap"}>
                            <div className="storeCoverWrap">
                                    <div className="container">
                                        <h1 style={{ display: 'none' }}>{window.sessionStorage.getItem("mainCategorySeoH1")}</h1>
                                        {(((this.state.store.length + this.state.storeGlobal.length) > 0) || this.state.isData === '1') ?
                                            <div>
                                                <div className="itemCategoryTitle"> Choose a store {localStorage.getItem('siteGlobalDelivery') === "true" ? "" : "in"}
                                                    <span>{locationSessionName} - {" "}
                                                        {(this.state.store.length + this.state.storeGlobal.length) > 0 ? <span> {this.state.store.length + this.state.storeGlobal.length} Store(s)</span> : <span>Please wait</span>}

                                                    </span>
                                                </div>
                                                {localStorage.getItem('siteGlobalDelivery') === "true" || localStorage.getItem('isPincodeBased') === "true" ? "" :
                                                    <div className="locationStoreIcon" onClick={this.locationClick}>
                                                        <span className="locationStoreClick">
                                                            {themeId == 29 ? <img src={locImg} alt="" /> : <img src={map} alt="" />}Change Location
                                                        </span>
                                                    </div>
                                                }
                                            </div>
                                            :
                                             <div>
                                                {localStorage.getItem('siteGlobalDelivery') === "true" ? "" :

                                                    <>
                                                        <div className="itemCategoryTitle">
                                                            Sorry...! No store(s) available in {locationSessionName}. Please change your location
                                                        </div>
                                                        <div className="locationStoreIcon" onClick={this.locationClick}>
                                                            <span className="locationStoreClick"><img src={map} alt="" />Change Location</span>
                                                        </div>
                                                    </>}
                                            </div>
                                            }
                                        {(themeId === '4') ?
                                            <Storelisting totalStores={((this.state.store.length + this.state.storeGlobal.length) > 0) ? this.state.store.length + this.state.storeGlobal.length : "0"}
                                                storeList={this.state.store}
                                            />
                                            :
                                            <div className="itemCategory">
                                                {this.state.store.map((store, index) => (
                                                    <div className="storeList">
                                                        <Link onClick={(e) => this.setStore(store)} to={(store.mainGroupCategory === 8 ? "/cmItemListing/0/0/0" : "/" + store.slug)}>
                                                            <div className="storeListContent">
                                                                <div className="storeListLogo">
                                                                    <img onError={this.addDefaultSrc} id={'ng' + index} src={store.imageName} alt="" />
                                                                    <input type="hidden" id={"hidng" + index} value={store.sellerLogoPath} />
                                                                </div>
                                                                <div className="storeListDetails">
                                                                    <div className="storeListName elipsis" title={store.storeName}>{store.storeName}</div>
                                                                    {localStorage.getItem('isPincodeBased') === "false" &&
                                                                        <div className="storeListLocation elipsis" title={store.locality + ', ' + store.cityName}>{store.locality}, {store.cityName}</div>}
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                ))}
                                                {this.state.storeGlobal.map((store, index) => (
                                                    <div className="storeList">
                                                        <Link onClick={(e) => this.setStore(store)} to={"/" + store.slug}>
                                                            <div className="storeListContent">
                                                                <div className="storeListLogo">
                                                                    <img onError={this.addDefaultSrc} id={'g' + index} src={store.imageName} alt="" />
                                                                    <input type="hidden" id={"hidg" + index} value={store.sellerLogoPath} />
                                                                </div>
                                                                <div className="storeListDetails">
                                                                    <div className="storeListName elipsis" title={store.storeName}>{store.storeName}</div>
                                                                    {localStorage.getItem('isPincodeBased') === "false" &&
                                                                        <div className="storeListLocation elipsis" title={store.locality + ', ' + store.cityName}>{store.locality}, {store.cityName}</div>}
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>
                            </div>
                        </div>
                        <Footer></Footer>
                        {/* {themeId === '29' ? <MraFooter></MraFooter> : <Footer></Footer>} */}
                        </Suspense>
                    </div>
                }
            </div>
        )
    }
}
