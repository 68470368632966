import React, { Component, Suspense } from 'react'
import Storeprofiledata from "../luna/store-profile-detail"
// let Header = React.lazy(() => import("../" + "luna" + "/header"));
// let Footer = React.lazy(() => import("../" + "luna" + "/footer"));

export default class Storelisting extends Component {
    render() {
        return (

            <div >
                <Suspense fallback={<div></div>}>
                    {/* <Header></Header> */}
                    <Storeprofiledata/>
                    {/* <Footer></Footer> */}
                </Suspense>
            </div>

        )
    }
}